import request from './request';

class AjaxStorage {
  constructor(request) {
    // request.axios.interceptors.request.use(function(config) {
    //   return config;
    // }, function(error) {
    //   return error;
    // });

    // request.axios.interceptors.response.use(function(response) {
    //   return response;
    // }, function(error) {
    //   return error;
    // });
  }
  
  data = {}
}

const ajaxStorage = new AjaxStorage(request);

export default ajaxStorage;