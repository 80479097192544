import { createHashHistory, } from 'history';

const history = createHashHistory({
  // basename: '/templates/eshop/v1/eshop/',
  // basename: 'iarts/eshop/',
});

history.listen(() => {
  window.scroll(0, 0);
});

export default history;