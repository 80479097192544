import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Link,
} from "react-router-dom";
import './Header.css';
import i18n from '../i18n';
import LanguageSelect from '../language-select/LanguageSelect';
import SearchWindow from '../search-window/SearchWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset, faSearch, faUserCircle, faBars, faShoppingBasket, faTimes, faSignInAlt, faSignOutAlt, faJedi, faCaretRight, faCaretLeft, faSpinner, faDotCircle as faDotCircleSolid } from '@fortawesome/free-solid-svg-icons'
import { faClock, faHeart, faUserCircle as faUserCircleO, faDotCircle, } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import storage from '../storage';

function Header(props) {
  const [searchWindowOpen, setSearchWindowOpen] = useState(false);
  const [navMobileOpen, setNavMobileOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [userMobileMenuOpen, setUserMobileMenuOpen] = useState(false);
  const [navigationMenuType, setNavigationMenuType] = useState(localStorage['navigation-menu-type'] || 'normal');
  const [categories, setCategories] = useState([]);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(localStorage['header-fixed'] || '0');

  const elementRef = React.createRef();

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
    });

    document.querySelector('.controls .user').addEventListener('click', function() {
      setUserMenuOpen(!document.querySelector('.controls .user .options').classList.contains('open'));
    });

    const onWindowClick = function(e) {
      if (!document.querySelector('.controls .user').contains(e.target)) {
        setUserMenuOpen(false);
      }
    };

    window.addEventListener('click', onWindowClick);

    document.querySelector('.navbar-mobile .controls .user').addEventListener('click', function() {
      setUserMobileMenuOpen(!document.querySelector('.controls .user .options').classList.contains('open'));
    });

    window.addEventListener('click', function(e) {
      if (!document.querySelector('.navbar-mobile .controls .user').contains(e.target)) {
        setUserMobileMenuOpen(false);
      }
    });

    setFetchingCategories(true);
    request.get(`api/categories`).then((response) => {
      let data = response.data;
      data = data.sort((a, b) => {
        if (parseInt(a.productsCount) > parseInt(b.productsCount)) {
          return -1;
        } else {
          return 1;
        }
      });
      setCategories(data);
      setFetchingCategories(false);
      storage.set('categories', data);
    });

    return () => {
      unlistenOnLanguageChange();
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const location = useLocation();
  useEffect(() => {
    setNavMobileOpen(false);
  }, [location]);

  useEffect(() => {
    if (navMobileOpen) {
      const mobileNavBtnClosePos = elementRef.current.querySelector('.navbar-mobile .btn-close').getBoundingClientRect();
      elementRef.current.querySelector('.navbar-mobile .content').style.top = mobileNavBtnClosePos.top + mobileNavBtnClosePos.height + (window.innerHeight * 0.09) + 'px';
    }
  }, [navMobileOpen]);

  function closeSearchWindow() {
    setSearchWindowOpen(false);
  }

  function openMobileNavMenu() {
    setNavMobileOpen(true);
  }

  function closeMobileNavMenu() {
    setNavMobileOpen(false);
  }

  function changeNavigationMenuType(type) {
    setNavigationMenuType(type);
    localStorage.setItem('navigation-menu-type', type);
  }

  function changeHeaderFixed(state) {
    setHeaderFixed(state);
    localStorage.setItem('header-fixed', state);
    props.onHeaderTypeChange(state === '1' ? 'sticky' : 'normal');
  }

  function logout() {
    request.get('api/logout').then((response) => {
      if (response.data.status === 'ok') {
        props.onLogout();
      }
    });
  }

  return (
    <div
      className={`header ${headerFixed === '1' ? 'fixed' : ''}`}
      ref={elementRef}
    >
      <div className="topbar">
        <div className="info">
          <div className="entry">
            <FontAwesomeIcon icon={faClock} />
            <span>{i18n.t('visit_time')}:</span>
            <span>{i18n.t('monday_to_friday_and_hours')}</span>
          </div>
          <div className="entry">
            <FontAwesomeIcon icon={faHeadset} />
            <span>{i18n.t('call_us')}:</span>
            <span>{storage.company.phone1}</span>
          </div>
        </div>
        <div className="options">
          <LanguageSelect></LanguageSelect>
          <Link to="/contact">{i18n.t('contact')}</Link>
          <FontAwesomeIcon icon={faFacebook} />
          {
            headerFixed === '0'
              ? <div className="change-header-type-option">
                  <FontAwesomeIcon
                    icon={faDotCircle}
                    className="btn-change-header-to-fixed"
                    onClick={() => changeHeaderFixed('1')}
                  />
                  <div className="tooltip">{i18n.t('change_header_to_fixed_tooltip')}</div>
                </div>
              : ''
          }
          {
            headerFixed === '1'
              ? <div className="change-header-type-option">
                  <FontAwesomeIcon
                    icon={faDotCircleSolid}
                    className="btn-change-header-to-normal"
                    onClick={() => changeHeaderFixed('0')}
                  />
                  <div className="tooltip">{i18n.t('change_header_to_normal_tooltip')}</div>
                </div>
              : ''
          }
        </div>
      </div>
      <div className="navbar">
        <div className="logo">
          <Link to="/">{storage.company.eshop_name}</Link>
        </div>
        <div className="navigation">
          {
            navigationMenuType === 'normal'
              ? <Fragment>
                  <Link to="/">{i18n.t('home_page')}</Link>
                  <Link to="/products">{i18n.t('products_navigation')}</Link>
                  <Link to="/categories">{i18n.t('categories')}</Link>
                  <Link to="/contact">{i18n.t('contact')}</Link>
                  <Link to="/basket">{i18n.t('basket')}</Link>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="btn-swap-menu-to-categories"
                    onClick={() => changeNavigationMenuType('categories')}
                  />
                </Fragment>
              : ''
          }
          {
            navigationMenuType === 'categories'
              ? <Fragment>
                  <FontAwesomeIcon
                    icon={faCaretLeft}
                    className="btn-swap-menu-to-normal"
                    onClick={() => changeNavigationMenuType('normal')}
                  />
                  {
                    fetchingCategories
                      ? <Fragment>
                          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                          <span>{i18n.t('fetching_categories')}</span>
                        </Fragment>
                      : ''
                  }
                  {
                    !fetchingCategories && categories.slice(0, 5).map((category, i) => {
                      return (
                        <Link
                          key={i}
                          to={`/categories/${category.id}`}
                        >
                          {category.name}
                        </Link>
                      );
                    })
                  }
                </Fragment>
              : ''
          }
        </div>
        <div className="controls">
          <div className="search">
            <FontAwesomeIcon
              icon={faSearch}
              size="lg"
              onClick={() => setSearchWindowOpen(true)}
            />
          </div>
          <div className="user">
            <FontAwesomeIcon
              icon={faUserCircle}
              size="lg"
            />
            <div 
              className={`options ${userMenuOpen ? 'open' : ''}`}
            >
              {
                props.user.authenticated === false
                  ? <Link to="/signin">
                      <FontAwesomeIcon icon={faSignInAlt} />
                      &nbsp;
                      {i18n.t('signin')}
                    </Link>
                  : ''
              }
              {
                props.user.authenticated === false
                  ? <Link to="/signup">
                      <FontAwesomeIcon icon={faJedi} />
                      &nbsp;
                      {i18n.t('signup')}
                    </Link>
                  : ''
              }
              {
                props.user.authenticated
                  ? <Link to="/account">
                      <FontAwesomeIcon icon={faUserCircleO} />
                      &nbsp;
                      {i18n.t('my_account')}
                    </Link>
                  : ''
              }
              {
                props.user.authenticated
                  ? <Link to="" onClick={logout}>
                      <FontAwesomeIcon icon={faSignOutAlt} />
                      &nbsp;
                      {i18n.t('signout')}
                    </Link>
                  : ''
              }
            </div>
          </div>
          {/* <div className="favourites">
            <FontAwesomeIcon
              icon={faHeart}
              size="lg"
            />
          </div> */}
          <div className="basket">
            <FontAwesomeIcon
              icon={faShoppingBasket}
              size="lg"
              onClick={props.onOpenBasket}
            />
          </div>
        </div>
        <div className="mobile-controls">
          <div className="btn-mobile-navigation">
            <FontAwesomeIcon
              icon={faBars}
              onClick={openMobileNavMenu}
            />
          </div>
        </div>
      </div>
      <div className={`navbar-mobile ${!navMobileOpen ? 'hidden' : ''}`}>
        <FontAwesomeIcon
          icon={faTimes}
          className="btn-close"
          onClick={closeMobileNavMenu}
        />
        <div className="content">
          <div className="controls">
            <div className="search">
              <FontAwesomeIcon
                icon={faSearch}
                size="2x"
                onClick={() => setSearchWindowOpen(true)}
              />
            </div>
            <div className="user">
              <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
              />
              <div 
                className={`options ${userMobileMenuOpen ? 'open' : ''}`}
              >
                {
                  props.user.authenticated === false
                    ? <Link to="/signin">
                        <FontAwesomeIcon icon={faSignInAlt} />
                        &nbsp;
                        {i18n.t('signin')}
                      </Link>
                    : ''
                }
                {
                  props.user.authenticated === false
                    ? <Link to="/signup">
                        <FontAwesomeIcon icon={faJedi} />
                        &nbsp;
                        {i18n.t('signup')}
                      </Link>
                    : ''
                }
                {
                  props.user.authenticated
                    ? <Link to="">
                        <FontAwesomeIcon icon={faUserCircleO} />
                        &nbsp;
                        {i18n.t('my_account')}
                      </Link>
                    : ''
                }
                {
                  props.user.authenticated
                    ? <Link to="">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        &nbsp;
                        {i18n.t('signout')}
                      </Link>
                    : ''
                }
              </div>
            </div>
            <div className="favourites">
              <FontAwesomeIcon
                icon={faHeart}
                size="2x"
              />
            </div>
            <div className="basket">
              <FontAwesomeIcon
                icon={faShoppingBasket}
                size="2x"
                onClick={props.onOpenBasket}
              />
            </div>
          </div>
          <div className="navigation">
            <Link to="/">{i18n.t('home_page')}</Link>
            <Link to="/products">{i18n.t('products_navigation')}</Link>
            <Link to="/categories">{i18n.t('categories')}</Link>
            <Link to="/contact">{i18n.t('contact')}</Link>
            <Link to="/basket">{i18n.t('basket')}</Link>
          </div>
        </div>
      </div>
      <SearchWindow 
        open={searchWindowOpen}
        onClose={closeSearchWindow}
      />
    </div>
  );
}

export default Header;
