import './Basket.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import history from '../history';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTruckMonster } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import NumberPicker from '../number-picker/NumberPicker';
import request from '../request';
import basket from '../basket';

class Basket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      products: [],
      productsUpdated: false,
      subtotal: 0,
    };

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.unlisten = history.listen((location) => {
      this.setState({
        ...this.state,
        open: false,
      });
    });

    this.props.attachListenerOnOpen(() => {
      this.setState({
        ...this.state,
        open: true,
      });
    });

    this.setState({
      ...this.state,
      products: basket.products,
      productsUpdated: true,
    });

    basket.onChange(() => {
      this.setState({
        ...this.state,
        products: basket.products,
        productsUpdated: true,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.productsUpdated) {
      let subtotal = 0;
      this.state.products.forEach((product) => {
        subtotal += (product.sale || product.price) * product.quantity;
      });
      this.setState({
        ...this.state,
        subtotal: subtotal,
        productsUpdated: false,
      });
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  close() {
    this.setState({
      ...this.state,
      open: false,
    });
  }

  addProduct(product, quantity) {
    basket.setQuantity(product, quantity);
  }

  removeProduct(product) {
    basket.removeProduct(product.id);
  }

  render() {
    return (
      <div className={`basket ${this.state.open ? 'open' : ''}`}>
        <div
          className="backdrop"
          onClick={this.close}
        ></div>
        <div className="container">
          <div className="header">
            <h3>{i18n.t('your_basket')}&nbsp;({Object.keys(this.state.products).length})</h3>
            <FontAwesomeIcon
              icon={faTimes}
              className="btn-close"
              onClick={this.close}
            />
          </div>
          <div className="products">
            {
              Object.keys(this.state.products).length === 0
                ? <div className="fs-sm text-center">{i18n.t('empty_basket')}</div>
                : ''
            }
            { 
              this.state.products.map((product, i) => {
                return (
                  <div
                    className="product"
                    key={i}
                  >
                    <div
                      className="image"
                      style={{backgroundImage: "url('" + product.thumbnail + "')"}}
                    ></div>
                    <div className="info">
                      <Link
                        to={`/products/${product.id}`}
                        className="name"
                      >
                        <span className="fs-sm">&times;{product.quantity}</span>
                        &nbsp;
                        {product.name}
                      </Link>
                      <span className="price">
                        {parseFloat(product.sale || product.price).toFixed(2)}&nbsp;€
                      </span>
                      <div className="controls">
                        <NumberPicker
                          value={product.quantity}
                          onChange={(value) => this.addProduct(product, value)}
                        />
                        <button
                          type="button"
                          className="btn btn-sm btn-outline"
                          onClick={() => this.removeProduct(product)}
                        >
                          {i18n.t('remove_product_from_basket')}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div className="amounts">
            <div className="subtotal">
              <span>{i18n.t('subtotal')}</span>
              <span>{this.state.subtotal.toFixed(2)}&nbsp;€</span>
            </div>
          </div>
          <div className="controls">
            <Link
              to="/checkout"
              className="btn-checkout"
            >
              {i18n.t('continue_to_checkout')}
            </Link>
            <Link
              to="/basket"
              className="btn-cart"
            >
              {i18n.t('view_cart')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Basket;