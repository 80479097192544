import './ProductPage.css';
import React, { useEffect, useState, useCallback, } from 'react';
import { Link, useParams, } from 'react-router-dom';
import request from '../request';
import config from '../config';
import NumberPicker from '../number-picker/NumberPicker';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShoppingBasket, faTimes, faPaperPlane, faBasketballBall, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import favouritesHelper from '../favouritesHelper';
import history from '../history';
import ProductAvailaibility from '../product-availability/ProductAvailability';
import basket from '../basket';
import ProductAttributes from '../product-attributes/ProductAttributes';

function ProductPage(props) {
  const params = useParams();
  
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const [product, setProduct] = useState({
    availability: {},
  });
  const [showToast, setShowToast] = useState(false);
  const [count, setCount] = useState(1);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const fetchProduct = () => {
    request.get(`api/product/${params.productId}`).then((response) => {
      setProduct({
        id: response.data.id,
        name: response.data.title,
        thumbnail: response.data.thumbnail,
        code: response.data.code,
        miniDescription: response.data.mini_description,
        description: response.data.description,
        image: response.data.thumbnail,
        rating: response.data.rating,
        price: response.data.initial_price,
        discountPrice: response.data.price,
        availability: response.data.availability,
        variants: response.data.variants,
      });
      setFetchingProduct(false);

      let lastSeenProducts = localStorage.getItem('last-seen-products');
      if (lastSeenProducts) {
        lastSeenProducts = JSON.parse(lastSeenProducts);
      } else {
        lastSeenProducts = [];
      }

      if (lastSeenProducts.indexOf(params.productId) === -1) {
        lastSeenProducts.push(params.productId);
        if (lastSeenProducts.length > 8) {
          lastSeenProducts.shift();
        } 
      }
      
      localStorage.setItem('last-seen-products', JSON.stringify(lastSeenProducts));
    });
  }

  useEffect(() => {
    setFetchingProduct(true);

    fetchProduct();  

    const unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
    });

    return () => {
      unlistenOnLanguageChange();
    };
  }, []);

  useEffect(() => {
    fetchProduct();
  }, [params.productId]);

  function onCountChange(value) {
    setCount(value);
  }

  function closeToast() {
    setShowToast(false);
  }

  function addToBasket() {
    basket.addProduct(product, count);
    setShowToast(true);
  }

  function addToFavourite() {
    favouritesHelper.add(product);
  }

  return (
    <div className="product-page">
      <div className={`skeleton ${fetchingProduct === false ? 'hidden' : ''}`}>
        <div className="skeleton-image"></div>
        <div className="skeleton-details"></div>
      </div>
      <div className={`basic-info ${fetchingProduct ? 'hidden' : ''}`}>
        <div className="image">
          {
            product.thumbnail && product.thumbnail.indexOf('http') > -1
              ? <img src={product.thumbnail} />
              : ''
          }
          {
            product.thumbnail && product.thumbnail.indexOf('http') === -1
              ? <img src={`${config.imagesBaseURL}products/${product.thumbnail}`} />
              : ''
          }
        </div>
        <div className="details">
          <span className="code">#{product.code}</span>
          <span className="name">{product.name}</span>
          <div className="mini-description" dangerouslySetInnerHTML={{ __html: product.miniDescription }}></div>
          <div className="price">
            {
              product.price !== product.discountPrice && parseFloat(product.discountPrice) > 0
                ? <span className="original-price">{parseFloat(product.price).toFixed(2)}&nbsp;€</span>
                : ''
            }
            <span className="current-price">{parseFloat(product.discountPrice) > 0 ? parseFloat(product.discountPrice).toFixed(2) : parseFloat(product.price).toFixed(2)}&nbsp;€</span>
          </div>
          
          {product.availability && <ProductAvailaibility data={product.availability} />}
          
          <div className="mt-5">
            <ProductAttributes data={product} />
          </div>

          <div className="controls">
            <NumberPicker
              onChange={onCountChange}
            />

            <button
              type="button"
              className="btn btn-lg"
              // style={{backgroundColor: '#28a745'}}
              onClick={addToBasket}
            >
              <FontAwesomeIcon icon={faShoppingBasket} />
              &nbsp;
              {i18n.t('add_to_basket')}
            </button>

            <button
              type="button"
              className="btn btn-lg btn-outline"
              onClick={addToFavourite}
            >
              <FontAwesomeIcon icon={faHeart} />
            </button>
          </div>
        </div>
      </div>
      <div className={`extra-info ${fetchingProduct ? 'hidden' : ''}`}>
        <div className="header">{i18n.t('description')}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: product.description }}></div>
      </div>
      <div className={`toast ${showToast ? 'open' : ''}`}>
        <FontAwesomeIcon
          icon={faTimes}
          className="btn-close"
          onClick={closeToast}
        />
        {i18n.t('product_added')}
        &nbsp;
        <Link to="/basket">{i18n.t('to_your_basket')}</Link>
      </div>
    </div>
  );
}

export default ProductPage;