import React from 'react';

class BlogPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [
        {
          id: 1,
          title: 'Lorem ipsum dolor',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam nisi eros, mollis sed tincidunt nec, sodales in nulla. Quisque odio ligula, maximus eu tristique posuere, ultricies a lacus. Etiam eu varius magna. Nam consectetur nibh metus, in sodales dolor pulvinar et. Integer egestas iaculis purus, at convallis mauris lacinia ac. Proin non sagittis nibh. Cras venenatis venenatis bibendum. Fusce porttitor nulla at tincidunt efficitur. Nulla non metus a tortor tincidunt hendrerit in in arcu. Vestibulum ut nibh lacinia, volutpat magna sit amet, imperdiet nisi. Maecenas congue luctus tortor in tempus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam leo augue, viverra quis commodo ut, dignissim a dui. Proin posuere urna non felis feugiat, quis dapibus eros venenatis.',
          image: 'https://via.placeholder.com/500x500',
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam nisi eros, mollis sed tincidunt nec, sodales in nulla. Quisque odio ligula, maximus eu tristique posuere, ultricies a lacus. Etiam eu varius magna. Nam consectetur nibh metus, in sodales dolor pulvinar et. Integer egestas iaculis purus, at convallis mauris lacinia ac. Proin non sagittis nibh. Cras venenatis venenatis bibendum. Fusce porttitor nulla at tincidunt efficitur. Nulla non metus a tortor tincidunt hendrerit in in arcu. Vestibulum ut nibh lacinia, volutpat magna sit amet, imperdiet nisi. Maecenas congue luctus tortor in tempus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam leo augue, viverra quis commodo ut, dignissim a dui. Proin posuere urna non felis feugiat, quis dapibus eros venenatis.',
          image: 'https://via.placeholder.com/500x500',
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam nisi eros, mollis sed tincidunt nec, sodales in nulla. Quisque odio ligula, maximus eu tristique posuere, ultricies a lacus. Etiam eu varius magna. Nam consectetur nibh metus, in sodales dolor pulvinar et. Integer egestas iaculis purus, at convallis mauris lacinia ac. Proin non sagittis nibh. Cras venenatis venenatis bibendum. Fusce porttitor nulla at tincidunt efficitur. Nulla non metus a tortor tincidunt hendrerit in in arcu. Vestibulum ut nibh lacinia, volutpat magna sit amet, imperdiet nisi. Maecenas congue luctus tortor in tempus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam leo augue, viverra quis commodo ut, dignissim a dui. Proin posuere urna non felis feugiat, quis dapibus eros venenatis.',
          image: 'https://via.placeholder.com/500x500',
        },
      ],
    };
  }

  render() {
    return (
      <div className="blog-page">
        {
          this.state.articles.map((article, i) => {
            return (
              <div
                key={i}
              >
                {article.id}
                {article.title}
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default BlogPage;