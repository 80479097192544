const favouritesHelper = {
  favourites: JSON.parse(localStorage.getItem('favourites') || '[]'),

  onChangeFns: [],
  onChange(fn) {
    this.onChangeFns.push(fn);
  },
  
  handleOnChangeEvent() {
    localStorage.setItem('favourites', JSON.stringify(this.favourites));
    this.onChangeFns.forEach((fn) => fn());
  },

  add(product) {
    const index = this.favourites.findIndex((p) => p.id === product.id);
    const alreadyAdded = index > -1;
    if (!alreadyAdded) {
      this.favourites.push(product);
    } else {
      this.favourites.splice(index, 1);
    }
    this.handleOnChangeEvent();

    return !alreadyAdded;
  }
};

window.favouritesHelper = favouritesHelper;

export default favouritesHelper;