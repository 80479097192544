import './NumberPicker.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'

class NumberPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || 1,
    };

    this.plus = this.plus.bind(this);
    this.minus = this.minus.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        ...this.state,
        value: this.props.value,
      });
    }
  }

  plus() {
    let value = this.state.value + 1;
    this.setState({
      ...this.state,
      value: value,
    });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value);
    }
  }

  minus() {
    let value = this.state.value - 1;
    value = value >= 1 ? value : 1;
    this.setState({
      ...this.state,
      value: value,
    });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <div className="number-picker">
        <span>{this.state.value}</span>
        <div className="controls">
          <FontAwesomeIcon
            icon={faCaretUp}
            size="lg"
            onClick={this.plus}
          />
          <FontAwesomeIcon
            icon={faCaretDown}
            size="lg"
            onClick={this.minus}
          />
        </div>
      </div>
    );
  }
}

export default NumberPicker;