import './ContactPage.css';
import React from 'react';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faGlobe, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import request from "../request";

class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: 'Name',
      email: 'Email',
      subject: 'Subject',
      message: 'Message',
      showMessage: false, 
      responseStatus:'',  
      responseMessage: '',  
      
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitContact = this.submitContact.bind(this);
  }

  handleInputChange(event, inputName) {    
    this.setState({
      ...this.state,
      [inputName]: event.target.value,      
    });

  }


  submitContact(event) {    
    event.preventDefault();
    let name = this.state.name;
    let email = this.state.email;
    let subject = this.state.subject;
    let message = this.state.message;
    
    request.post('api/send-email', {  "name" : this.state.name, "email" : this.state.email, "subject" : this.state.subject, "message" : this.state.message} ).then( (response) => {
      console.log( response.data );
      this.setState({
        ...this.state,
        showMessage: true,
        responseStatus: response.data.status,     
        messageResponse: response.data.message
      })
      
    });

  }


  render() {
    return (

      <div className="contact-page">
        <section className="contact_form">
          <div className="flex-container ">
            <form onSubmit={this.handleSubmit} >

                <h2>{i18n.t('get_in_touch')}</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                <div className="flex-item-left" >

                  <div className="w-50 col-left">
                    <input type="text" className="form-control"  placeholder="Name"  onChange={(e) => { this.handleInputChange(e , 'name') }}  />
                  </div>

                  <div className="w-50 col-right">
                    <input type="email" className="form-control" placeholder="Email"  onChange={ (e) => {this.handleInputChange(e, 'email') } } />       
                  </div> 

                  <input  type="text" className="form-control w-100" placeholder="Subject"  onChange={ (e) => {this.handleInputChange(e, 'subject') } } />

                  <textarea className="form-control w-100" placeholder="Μessage"  onChange={ (e) => {this.handleInputChange(e, 'message') } }></textarea>                       

                  <input className="btn btn-outline mb-20" type="submit" onClick={this.submitContact} name="submit" placeholder="Send" />   

                </div>

                <div className={`alert ${this.state.showMessage === false ? 'hidden' : ''} ${this.state.responseStatus === 'ok' ? ' alert-success' : 'alert-danger'}`}>                  
                  {this.state.messageResponse}
                </div>

             </form>

            <div className="flex-item-right">               
              <iframe 
                height="450"
                width="100%"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48320.39666380446!2d-73.965441!3d40.805449!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f63c75af2d65%3A0xf45542f6cb090cc9!2s2880%20Broadway%2C%20New%20York%2C%20NY%2010025!5e0!3m2!1sel!2sus!4v1626777799578!5m2!1sel!2sus"
                style={{ border:0 }}
                allowFullScreen={true}
                loading="lazy" >
              </iframe>
            </div> 

          </div>
        </section>

        <section className="contact_details bg-grey">
          <div className="flex-container">
            <div className="item">
              <FontAwesomeIcon icon={faPhone}  />
              <p>+012 345 678 123</p>
              <p>+012 345 678 123</p>
            </div>
            <div className="item">
              <FontAwesomeIcon icon={faPrint}  />
              <p>+012 345 678 123</p>
              <p>+012 345 678 123</p>
            </div>
            <div className="item">
              <FontAwesomeIcon icon={faGlobe} />
              <p>demo@example.com</p>
              <p>demo@example.com</p> 
            </div>
            <div className="item">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <p>1600 Amphitheatre Parkway</p>
              <p>New York WC1 1BA</p>
            </div>
          </div>
        </section>  
      </div>

    );
  }
}

export default ContactPage;