import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect} from "react";
import './Modal.css';
// import { } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';




const Modal = props => {  

  const closeOnEscapeKeyDown = (e) => {
    if((e.charCode || e.keyCode) === 27){
      props.onClose()
    }
  }

  useEffect(() => {
    document.body.addEventListener('keydown',closeOnEscapeKeyDown)
    return function cleanUp() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
    }
  }, [])

  // if (!props.showModal) {
  //   return null;
  // }

  return(
    <div className={`modal ${props.showModal ? 'show' : '' }`} onClick={props.onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h4 className="modal-title">{props.modalTitle}
            <span onClick={props.onClose}><FontAwesomeIcon icon={faTimes}/></span>
          </h4>
        </div>
        <div className="modal-body">
          {props.children}
        </div>
        {/* <div className="modal-footer">
            <button 
              onClick={props.onClose}
              className="btn outline"
            >Close
            </button>
        </div> */}
      </div>
    </div>
  )
}

export default Modal


