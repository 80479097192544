import request from './request';

class Basket {
  constructor() {
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      localstorageProducts = {};
    }
    let promises = [];
    for (let productId in localstorageProducts) {
      promises.push(request.get(`api/product/${productId}`).then((response) => {
        this.products.push({
          ...response.data,
          name: response.data.title,
          quantity: localstorageProducts[productId],
        });
      }));
    }
    Promise.all(promises).then(() => {
      this.onChangeFns.forEach((fn) => fn());
    });
  }

  products = [];
  onAddProductFns = [];
  onRemoveProductFns = [];
  onChangeFns = [];

  addProduct(product, quantity) {
    const productIndex = this.products.findIndex((p) => p.id === product.id);
    if (productIndex > -1) {
      this.products[productIndex].quantity += quantity;
    } else {
      this.products.push({
        ...product,
        quantity: quantity,
      });
    }
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      localstorageProducts = {};
    }
    if (product.id in localstorageProducts) {
      localstorageProducts[product.id] += quantity;
    } else {
      localstorageProducts[product.id] = quantity;
    }
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onAddProductFns.forEach((fn) => {
      fn(product);
    });
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }

  setQuantity(product, quantity) {
    const productIndex = this.products.findIndex((p) => p.id === product.id);
    this.products[productIndex].quantity = quantity;
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      localstorageProducts = {};
    }
    localstorageProducts[product.id] = quantity;
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onAddProductFns.forEach((fn) => {
      fn(product);
    });
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }

  removeProduct(productId) {
    this.products.splice(this.products.findIndex((p) => p.id === productId), 1);
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      localstorageProducts = {};
    }
    delete localstorageProducts[productId];
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onRemoveProductFns.forEach((fn) => {
      fn(productId);
    });
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }

  onAddProduct(fn) {
    this.onAddProductFns.push(fn);
  }

  onRemoveProduct(fn) {
    this.onRemoveProductsFns.push(fn);
  }

  onChange(fn) {
    this.onChangeFns.push(fn);
  }

  clear() {
    this.products = [];
    localStorage.setItem('basket', '{}');
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }
}

const basket = new Basket();
window.basket = basket;

export default basket;