import './SigninPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faGoogle, } from '@fortawesome/free-brands-svg-icons'
import request from '../request'
import Modal from '../modal/Modal';
import history from '../history';

class SigninPage extends React.Component {
  constructor(props){
    super(props);    

    this.state = {
      email: "", // sign in
      password: "", // sign in
      isValidEmail: true ,// username email 
      passwordError: "",            
      checked: false, 
      showMessage: false,
      message: "",
      showErrorModal: false,
      modalStatus: false,
      emailForgot:"", // modal form forgot 
    };

    this.elementRef = React.createRef();
    
    this.submitSignin = this.submitSignin.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this); 
    this.handleCheckbox = this.handleCheckbox.bind(this);   
    this.showMessage = this.showMessage.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.submitForgot = this.submitForgot.bind(this);  
  }
  
  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  handleCheckbox (event) {
    const target = event.target;
    const value = target.checked;    
    const name = target.name;

    this.setState({
      ...this.state,
      checked: value,
    });
  }  

  handleEmail(e) {
    let validate = this.validateEmail(e.target.value);    
    let validStatus;
    
    if( validate === true ) {
      validStatus = true;
    }else {
      validStatus = false;
    }

    this.setState({
      ...this.state,
      email: e.target.value,  
      isValidEmail: validStatus,   
    })
  }

  handlePassword(e) {
    let errorValue;
    if(e.target.value === '') {
       errorValue = 'error';
    }else {
       errorValue = '';
    }

    this.setState({
      ...this.state,
      password: e.target.value,
      passwordError: errorValue,
    });
  }

  emailForgotInput(e) {
    this.setState({
      ...this.state,
      emailForgot: e.target.value
    })
  }

  showMessage(status, message) {   
    this.setState({
      ...this.state,
      message: message,
      showMessage: true
    });
  }

  showMessageModal(status, message) {    
    this.setState({
      ...this.state,
      message: message,
      showErrorModal: true
    });
  } 

  validateEmail(email) {       
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;     
    if(email.match(mailformat)) {
      return (true);  //valid
    }else{
      return (false);      
    }  
  }

  // Submit sign in
  submitSignin (e) {
    let email = this.state.email;  
    let validate = this.validateEmail(email);   
    let message; 

    if(validate === true && this.state.email !== '' && this.state.password !== '') {
      
      request.post(`api/signin`, { "email" : this.state.email, "password" : this.state.password, "remember" : this.state.checked === true ? true : false }).then((response) => {       
        if (response.data.status) {
          this.props.onLogin();
          history.push('/');
        } else {
          this.showMessage(response.data.status, response.data.message);
        }
      });

    } else {
      
      let passwordStatus = this.state.password === '' ? 'error' : '';
      
      if( validate === false &&  email === '' ){
        message = 'Παρακαλώ συμπληρώστε όλα τα πεδία';
      } else if ( validate === false ) {
        message = 'Παρακαλώ συμπληρώστε ένα έγκυρο email';
      } else {
        message = 'Παρακαλώ εισάγετε τον κωδικό σας';
      }


      this.setState({
        ...this.state,
        emailStatus: validate,
        passwordError: passwordStatus,
        showMessage: true,
        message: message
      })         

    } 
  }

  // submit forgot password
  submitForgot () {

    let email = this.state.emailForgot;  
    let valid = this.validateEmail(email);

    if( valid === true && email !== '') {    
      request.post( `api/forgot`, { "email" : email }).then( (response) => {
        this.showMessageModal(response.data.status, response.data.message, response.data);        
      });
    } else {
      if(email === ''){
        this.showMessageModal('error', 'Συμπληρώστε το πεδίο με το email σας');
      }else{
        this.showMessageModal('error', 'Το emαιl που δηλώσατε δεν είναι έγκυρο');
      }      
    }
  }

  render() {
    return (
      <div
        className="signin-page"
        ref={this.elementRef}
      >
        <div className="form">
          <div className="header">
            {i18n.t('signin_on_header')}
          </div>
          <div className="fields">
            <div className="input">
              <span>{i18n.t('email')}</span>
              <input 
                type="email"
                className={`${this.state.emailStatus === false ? 'error' : ''}`}
                value={this.state.email}
                onChange={ (e) => {this.handleEmail(e) }}
                onKeyDown={(e) => e.key === 'Enter' && this.submitSignin()}
              />
            </div>
            <div className="input">
              <div className="d-flex flex-direction-row justify-content-between align-items-start w-100">
                <span>{i18n.t('password')}</span>
                <p onClick={ () => this.setState({...this.state, modalStatus: true})} className="fs-sm forgot">{i18n.t('forgot_password')}</p>
              </div>
              <input 
                type="password" 
                className={`${this.state.passwordError == 'error' ? 'error' : ''}`}
                value={this.state.password}
                onChange={ (e) => { this.handlePassword(e) }}
                onKeyDown={(e) => e.key === 'Enter' && this.submitSignin()}
              />
            </div>
            <div className="mt-1">              
              <input 
                type="checkbox" 
                name="remember" 
                checked={this.state.checked}
                onChange={this.handleCheckbox }
               /> 
              <label>{i18n.t('remember_me')}</label>
            </div>

            <div className={`alert alert-danger login-response ${this.state.showMessage === false ? 'hidden' : ''} `}>
              {this.state.message}
            </div>

          </div>
          <div className="controls">
            <button
              onClick={this.submitSignin}
              type="button"
              className="btn-signin"
            >
              {i18n.t('signin_button')}
            </button>
            <div className="seperator">
              <span className="fs-sm">{i18n.t('alternatively')}</span>
            </div>
            <div className="login-with">
              <button                
                type="button"
                className="btn btn-outline"
              >
                <FontAwesomeIcon icon={faFacebookF} />
                &nbsp;
                Facebook
              </button>
              <button
                type="button"
                className="btn btn-outline"
              >
                <FontAwesomeIcon icon={faGoogle} />
                &nbsp;
                Google
              </button>
            </div>
          </div>
          <div className="text-center my-1 fs-md">
            {i18n.t('dont_have_an_account')}
            &nbsp;
            <Link to="/signup">{i18n.t('signup')}</Link>
          </div>
        </div>

        <Modal
          modalTitle = "Δημιουργία νέου κωδικού"
          showModal = {this.state.modalStatus}
          onClose = { () => this.setState({...this.state, modalStatus: false})}
        >
          <div className="container" >
            <div className="w-100">
              <label>Παρακαλώ εισάγετε το e-mail σας</label>
              <input
                type="email"
                className="form-control mb-2"
                onChange={ (e) => { this.emailForgotInput(e) } }
              />

              <div className={`error login-response mb-2 ${this.state.showErrorModal === false ? 'hidden' : ''}`}>
                {this.state.message}
              </div>

              <div className="mb-20">
                <input
                type="submit"
                className="btn btn-outline" 
                onClick={() => {this.submitForgot() }} 
                />
              </div>

            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SigninPage;