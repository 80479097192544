import './SignupPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import history from '../history';

class SignupPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: '',
        lastname: '',
        email: '',
        password: '',
        passwordConfirm: '',
      },
      checked: false,
      showError: false,
      emailValid: null,
      passwordValid: null
    };

    this.signupSubmit = this.signupSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateEmail2 = this.validateEmail2.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    // this.validatePassword = this.validatePassword.bind(this);
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }
  

  onInputChange(e, inputName) {
    let emailStatus = this.state.emailValid;

    if(inputName === 'email') {
      emailStatus = this.validateEmail(e);
    }   
    
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [inputName]: e.target.value,
      },
      emailValid: emailStatus,     
    });
  }
  

  handleCheckbox(event) {   
    const value = event.target.checked;
    this.setState({
      ...this.state,
      checked: value
    })
  }


  validateEmail(e) {
    const value = e.target.value;    
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;     
    if( value.match(mailformat) ) {       
      return (true);  //valid
    }else{     
      return (false); //not valid
    }        
  }

  validateEmail2(e) {    
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;     
    if( e.match(mailformat) ) {       
      return (true);  //valid
    }else{     
      return (false); //not valid
    }        
  }
  
  
  signupSubmit() {
    let name = this.state.form.name;
    let lastname = this.state.form.lastname;
    let email = this.state.form.email;
    let emailValid = this.validateEmail2(email);    
    let message = '';
    
    // let passwordValid = this.validatePassword();
    let passwordValid = false;
    let password = this.state.form.password ;
    let passwordConfirm = this.state.form.passwordConfirm ;
    let terms = this.state.checked;

    // validate password 
    if(  password !== '' && password && passwordConfirm !== '' && password === passwordConfirm  ) {
      passwordValid = true;
    }else {
      passwordValid = false;
    }        
    
   

    if(emailValid === true && passwordValid === true  && terms === true && name !== '' && lastname !== ''  ) {
      console.log('send ok');
      request.post(`api/signup`, {
        ...this.state.form        
      }).then((response) => {          
        if (response.data.status) {
          this.props.onLogin();
          history.push('/');
        } else {
          this.showMessage(response.data.status, response.data.message); 
        }
       
      });
    }else if (passwordValid === false && emailValid === false && terms === false && password === '' && passwordConfirm === '' && name === '' ){
      message = 'Παρακαλώ συμπληρώστε όλα τα πεδία';
    }else if( passwordValid === false){
      message = 'Ο κωδικός με τον κωδικό επιβεβαίωσης δεν είναι το ίδιο';
    }else if( emailValid === false) {
      message = 'Παρακαλώ συμπληρώστε ένα έγκυρο email';
    }else if ( name === '' || lastname === '') {
      message = 'Παρακαλώ συμπληρώστε όλα τα πεδία';
    }else {
      message = 'Παρακαλώ αποδεχτείτε τους όρους χρήσεις';
    }

    this.showMessage('error', message);
  }
  // End sign up



  showMessage(status, message){   
      this.setState({
        ...this.state,
        errorMessage: message,
        showError: true
      }) 
  }

  render() {
    return (
      <div className="signup-page">
        <div className="form">
          <div className="header">
            {i18n.t('signup_on_header')}
          </div>
          <div className="fields">
            <div className="input">
              <span>{i18n.t('name')}</span>
              <input
                type="text"
                value={this.state.form.name}
                onChange={(e) => this.onInputChange(e, 'name')}
              ></input>
            </div>
            <div className="input w-50">
              <span>{i18n.t('lastname')}</span>
              <input
                type="text"
                value={this.state.form.lastname}
                onChange={(e) => this.onInputChange(e, 'lastname')}
              ></input>
            </div>

            <div className="input w-100">
              <span>{i18n.t('email')}</span>
              <input
                type="email"
                className={this.state.emailValid === false ? 'error' : ''}
                value={this.state.form.email}
                onChange={(e) => {this.onInputChange(e, 'email'); } }
              ></input>
            </div>

            <div className="input">
              <span>{i18n.t('password')}</span>
              <input
                type="text"
                className={this.state.passwordValid === true ? 'error' : ''}
                value={this.state.form.password}
                onChange={(e) => this.onInputChange(e, 'password')}
              ></input>
            </div>
            <div className="input">
              <span>Επιβεβαίωση Κωδικού</span>
              <input
                type="text"
                className={this.state.passwordValid === true ? 'error' : ''}
                value={this.state.form.passwordConfirm}
                onChange={(e) => this.onInputChange(e, 'passwordConfirm')}
              ></input>
            </div>
          </div>

          <div className="fs-md pl-2 pt-2">            
            <input
              type="checkbox"
              checked={this.state.checked}
              onChange={this.handleCheckbox}
            />
            <label>{i18n.t('register_accept_terms')}</label>

          </div>

          <div className="controls">
            <div className={`alert alert-danger ${this.state.showError === false ? 'hidden' : ''}`}>
              {this.state.errorMessage}
            </div>
          </div>

          <div className="controls">
          
            <button
              type="button"
              className="btn btn-lg"
              onClick={this.signupSubmit}
            >
              {i18n.t('create_account_button')}
            </button>
            <div className="already-have-an-account-note">
              <span>{i18n.t('have_an_account')}</span>
              &nbsp;
              <Link to="/signin">{i18n.t('login_here')}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupPage;

