import { useState, useEffect, } from "react";
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import history from './history';
import ajaxStorage from "./ajax-storage";
import Header from './header/Header';
import Footer from './footer/Footer';
import ContactPage from './contact-page/ContactPage';
import HomePage from './home-page/HomePage';
import BlogPage from "./blog-page/BlogPage";
import CategoriesPage from "./categories-page/CategoriesPage";
import PageHeader from "./page-header/PageHeader";
import Basket from './basket/Basket';
import ProductPage from './product-page/ProductPage';
import Signin from './signin-page/SigninPage';
import Signup from './signup-page/SignupPage';
import ProductsPage from "./products-page/ProductsPage";
import BasketPage from "./basket-page/BasketPage";
import CheckoutPage from "./checkout-page/CheckoutPage";
import ProductsMiscPage from "./products-misc-page/ProductsMiscPage";
import AccountPage from "./account-page/AccountPage";
import request from "./request";
import storage from "./storage";

const onOpenBasketFns = [];

function App(props) {
  const [user, setUser] = useState({
    authenticated: false,
  });

  const [loading, setLoading] = useState(true);

  const [headerType, setHeaderType] = useState(localStorage['header-fixed'] === '1' ? 'sticky' : 'normal');

  function attachListenerOnOpenBasket(fn) {
    onOpenBasketFns.push(fn);
  }

  function onOpenBasket() {
    onOpenBasketFns.forEach((fn) => {
      fn();
    });
  }

  function headerTypeChanged(type) {
    setHeaderType(type);
  }

  useEffect(() => {
    request.get(`api/company`).then((response) => {
      storage.set('company', response.data);
      setLoading(false);
    });

    checkSignInStatus();
  }, []);

  useEffect(() => {
    if (!loading) {
      (document.querySelector('#loading') || { remove() {} }).remove();
    }
  }, [loading]);

  function onLogout() {
    setUser({
      authenticated: false,
    });
    history.push('/signin');
  }

  function checkSignInStatus() {
    request.get('api/status').then((response) => {
      console.log(response)
      if (response.data.login) {
        setUser({
          authenticated: true,
        });
        storage.set('me', response.data.data);
      }
    });
  }

  function onLogin() {
    setUser({
      authenticated: true,
    });

    checkSignInStatus();
  }

  return (
    <div className={`App ${headerType === 'sticky' ? 'header-fixed' : ''}`} style={{visibility: loading ? 'hidden' : 'visible'}}>
      <Router
        history={history}
      >
        <Header
          onOpenBasket={onOpenBasket}
          onHeaderTypeChange={headerTypeChanged}
          user={user}
          onLogout={onLogout}
        ></Header>
        <Switch>
          <Route exact path="/">
            <HomePage
            />
          </Route>
          <Route path="/products/:productId">
            <PageHeader
              
            />
            <ProductPage
            />
          </Route>
          <Route path="/products">
            <PageHeader
              page="products"
            />
            <ProductsMiscPage

            />
          </Route>
          <Route path="/categories/:categoryId">
            <PageHeader
              
            />
            <ProductsPage
            />
          </Route>
          <Route path="/categories/:categoryId/:page">
            <PageHeader
              
            />
            <ProductsPage
            
            />
          </Route>
          <Route path="/search/products/:query">
            <ProductsPage
            
            />
          </Route>
          <Route path="/contact">
            <PageHeader
              page={'contact'}
            />
            <ContactPage />
          </Route>
          <Route path="/blog">
            <PageHeader
              page={'blog'}
            />
            <BlogPage />
          </Route>
          <Route path="/categories">
            <PageHeader
              page={'categories'}
            />
            <CategoriesPage />
          </Route>
          <Route path="/checkout">
            <PageHeader
              page={'checkout'}
            />
            <CheckoutPage
            />
          </Route>
          <Route path="/signin">
            <PageHeader
              page={'signin'}
            />
            <Signin
              onLogin={onLogin}
            />
          </Route>
          <Route path="/signup">
            <PageHeader
              page={'signup'}
            />
            <Signup 
              onLogin={onLogin}
            />
          </Route>
          <Route path="/basket">
            <PageHeader
              page={'basket'}
            />
            <BasketPage
            />
          </Route>
          <Route path="/account">        
            <AccountPage />
          </Route>
        </Switch>
        <Footer></Footer>
        <Basket
          attachListenerOnOpen={attachListenerOnOpenBasket}
        ></Basket>
      </Router>
    </div>
  );
}

export default App;
