import React from 'react';
import i18n from '../i18n';
import './LanguageSelect.css';
import enFlagImg from '../assets/img/en-flag.png';
import elFlagImg from '../assets/img/el-flag.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'

const languagesData = {
  en: {
    image: enFlagImg,
    label: 'English',
  },
  el: {
    image: elFlagImg,
    label: 'Ελληνικά',
  },
}

class LanguageSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.elementRef = React.createRef();
    this.selectedOptionRef = React.createRef();
    this.optionsRef = React.createRef();

    this.onWindowClick = (e) => {
      if (!this.elementRef.current.contains(e.target)) {
        this.setState({
          ...this.state,
          open: false,
        });
      }
    };

    this.chooseLanguage = this.chooseLanguage.bind(this);
  }

  componentDidMount() {
    this.selectedOptionRef.current.addEventListener('click', () => {
      this.setState({
        ...this.state,
        open: !this.state.open,
      });
    });

    window.addEventListener('click', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  chooseLanguage(languageCode) {
    i18n.setLanguage(languageCode);
    this.setState({
      ...this.state,
      open: false,
    });
  }

  render() {
    return (
      <div
        className={`language-select ${this.state.open ? 'open' : ''}`}
        ref={this.elementRef}
      >
        <div
          className="selected option"
          ref={this.selectedOptionRef}
        >
          <img
            src={languagesData[i18n.language].image}
            height="17"
            alt=""
          />
          <span>{languagesData[i18n.language].label}</span>
          &nbsp;
          <FontAwesomeIcon icon={faCaretDown} className={`${this.state.open === true ? 'hidden' : ''}`} />
          <FontAwesomeIcon icon={faCaretUp} className={`${this.state.open === false ? 'hidden' : ''}`} />
        </div>
        <div
          className="options"
          ref={this.optionsRef}
        >
          {
            Object.keys(languagesData).map((languageCode, i) => {
              return (
                <div
                  className={`option ${languageCode === i18n.language ? 'hidden' : ''}`}
                  key={i}
                  onClick={this.chooseLanguage.bind(this, languageCode)}
                >
                  <img
                    src={languagesData[languageCode].image}
                    height="17"
                    alt=""
                  />
                  <span>{languagesData[languageCode].label}</span>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default LanguageSelect;