import './ProductAttributes.css';
import React from 'react';
import i18n from '../i18n';
import Select from 'react-select';

class ProductAttributes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attributes: {},
      filteredAttributes: {},
      selectedAttributes: {},
    };

    this.renderAttributes = this.renderAttributes.bind(this);
    this.onAttributeSelect = this.onAttributeSelect.bind(this);
    this.filterAttributes = this.filterAttributes.bind(this);
  }

  componentDidMount() {
    this.renderAttributes();

    i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.variants !== this.props.data.variants) {
      this.renderAttributes();
    }
  }

  renderAttributes() {
    const groupedAttributes = {};

    if ('variants' in this.props.data) {
      const allAttributes = [];
      this.props.data.variants.forEach((variant) => {
        variant.attributes.forEach((attribute) => {
          if (allAttributes.findIndex((a) => a.value_id === attribute.value_id) === -1) {
            allAttributes.push(attribute);
          }
        });
      });

      allAttributes.forEach((attribute) => {
        if (!(attribute.attribute_category in groupedAttributes)) {
          groupedAttributes[attribute.attribute_category] = [];
          groupedAttributes[attribute.attribute_category].ref = React.createRef();
        }

        groupedAttributes[attribute.attribute_category].push({
          label: attribute.value,
          value: attribute.value_id,
        });
      });
    }

    this.setState({
      ...this.state,
      attributes: groupedAttributes,
      filteredAttributes: groupedAttributes,
    });
  }

  filterAttributes() {
    let filteredAttributes = {};
    if (Object.keys(this.state.selectedAttributes).length === 0) {
      filteredAttributes = { ...this.state.attributes };
    } else {
      Object.keys(this.state.attributes).map((attributeName) => {
        filteredAttributes[attributeName] = filteredAttributes[attributeName] || [];
        const isSelectedAttribute = typeof this.state.selectedAttributes[attributeName] !== 'undefined' && this.state.selectedAttributes[attributeName] !== null;
        if (isSelectedAttribute) {
          const selectedAttributeValue = this.state.selectedAttributes[attributeName].value;
          this.props.data.variants.forEach((variant) => {
            const attributeExistsInVariantAttributes = variant.attributes.findIndex((a) => a.attribute_category === attributeName && a.value_id === selectedAttributeValue) > -1;
            if (attributeExistsInVariantAttributes) {
              variant.attributes.forEach((attribute) => {
                if (typeof filteredAttributes[attribute.attribute_category] === 'undefined') {
                  filteredAttributes[attribute.attribute_category] = [];
                }
                const attributeAlreadyAdded = filteredAttributes[attribute.attribute_category].findIndex((o) => o.value === attribute.value_id) > -1;
                if (!attributeAlreadyAdded) {
                  filteredAttributes[attribute.attribute_category].push({
                    label: attribute.value,
                    value: attribute.value_id,
                  });
                }
              });
            }
          });
        }
      });
    }

    this.setState({
      ...this.state,
      filteredAttributes: filteredAttributes,
    });
  }

  onAttributeSelect(attribute, option) {
    const sa = {
      ...this.state.selectedAttributes,
    };
    sa[attribute] = option;
    this.setState({
      ...this.state,
      selectedAttributes: sa,
    });

    setTimeout(() => {
      this.filterAttributes();
    });
  }

  render() {
    return (
      <div className="ProductAttributes">
        {
          Object.keys(this.state.attributes).map((attributeName, i) => {
            return (
              <Select
                key={i}
                className="select"
                options={this.state.filteredAttributes[attributeName]}
                placeholder={`${i18n.t('choose')} ${attributeName}`}
                value={this.state.selectedAttributes[attributeName]}
                onChange={(selectedOption) => this.onAttributeSelect(attributeName, selectedOption)}
                ref={this.state.attributes[attributeName].ref}
              />
            );
          })
        }

        {/* <button
          className="btn btn-outline"
          onClick={() => {
            this.setState({
              ...this.state,
              selectedAttributes: {},
            });

            setTimeout(() => {
              this.filterAttributes();
            });
          }}
        >
          {i18n.t('clear')}
        </button> */}
      </div>
    );
  }
}

export default ProductAttributes;