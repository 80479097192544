import './CategoriesPage.css';
import React from 'react';
import SkeletonCategoryItem from '../category-skeleton-item/SkeletonCategoryItem';
import CategoryItem from '../category-item/CategoryItem';
import i18n from '../i18n';
import request from '../request';

class CategoriesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    request.get(`api/categories`).then((response) => {
      let data = response.data;
      data = data.sort((a, b) => {
        if (parseInt(a.productsCount) > parseInt(b.productsCount)) {
          return -1;
        } else {
          return 1;
        }
      });
      this.setState({
        ...this.state,
        categories: data.map((category) => {
          return {
            id: category.id,
            name: category.name,
            image: category.image,
            productCount: category.productsCount,
            parentCategoryId: parseInt(category.parent_id),
          };
        }),
      });
    });
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  render() {
    return (
      <div className="categories-page">
        <div className="list">
          {
            this.state.categories.filter((c) => c.parentCategoryId === 0).map((category, i) => {
              return (
                <CategoryItem
                  key={i}
                  data={category}
                />
              );
            })
          }
          {
            this.state.categories.length === 0 && Array.from(Array(12)).map((n, i) => {
              return (
                <SkeletonCategoryItem key={i} />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default CategoriesPage;