class I18n {
  language = 'el';
  languageChangeFns = [];
  translations = {
    en: {
      visit_time: 'Visit time',
      call_us: 'Call us',
      monday_to_friday_and_hours: 'Mon-Fri 10:00-18:00',
      company_address: 'Skiathou 2, Ntepo',
      home_page: 'Home',
      products_navigation: 'Products',
      categories: 'Categories',
      basket: 'Basket',
      contact: 'Contact',
      blog: 'Blog',
      search: 'Search',
      close: 'Close',
      shop_by_category: 'Shop by Category',
      featured_products_title: 'Featured',
      favourite_products_title: 'Favourites',
      seen_products_title: 'Seen Products',
      products: 'products',
      get_in_touch: 'Get in Touch',
      home: 'Home',
      add_to_basket: 'Add to basket',
      products_on_header: 'Products Page',
      categories_on_header: 'Shop Categories',
      contact_on_header: 'Contact Page',
      basket_on_header: 'Your Basket',
      blog_on_header: 'Blog',
      signin_on_header: 'Sign in',
      signup_on_header: 'Sign up',
      checkout_on_header: 'Complete Order',
      your_basket: 'Your Basket',
      subtotal: 'Subtotal',
      continue_to_checkout: 'Continue to Checkout',
      view_cart: 'View Cart',
      remove_product_from_basket: 'Remove',
      empty_basket: 'Your basket is empty',
      product_added: 'Product added',
      to_your_basket: 'to your basket',
      add_to_favourite: 'Add to Favourite',
      signin: 'Sign In',
      signout: 'Sign Out',
      my_account: 'My Account',
      support: 'Support',
      help: 'Help',
      contact_us: 'Contact us',
      feedback: 'Feedback',
      unsubscribe: 'Unsubscribe',
      policies: 'Policies',
      privacy_policy: 'Privacy Policy',
      terms_of_use: 'Terms of Use',
      shipping: 'Shipping',
      return_policy: 'Return',
      stay_up_to_date: 'Stay up to Date',
      newsletter_info: '*Subsribe to our newsletter to receive offers, and latest news.',
      subscribe: 'Subsribe',
      your_email: 'Your Email',
      name: 'Name',
      lastname: 'Lastname',
      email: 'Email',
      password: 'Password',
      remember_me: 'Remember me',
      dont_have_an_account: `You don't have an account?`,
      signin_button: 'Sign in',
      signup: 'Sign up',
      alternatively: 'Alternatively',
      forgot_password: 'Forgot Password?',
      register_accept_terms: 'By register, I read & accept the terms.',
      create_account_button: 'Create Account',
      have_an_account: 'Have an account?',
      login_here: 'Login here',
      filters: 'Filters',
      sort_by: 'Sort by',
      price: 'Price',
      price_low_to_high: 'Price, low to high',
      price_high_to_low: 'Price, high to low',
      image: 'Image',
      product_name: 'Product Name',
      unit_price: 'Unit Price',
      quantity: 'Qty',
      action: 'Action',
      order_total: 'Order Total',
      taxes: 'Taxes',
      grand_total: 'Grand Total',
      continue_shopping: 'Continue Shopping',
      empty_basket: 'There are no products in your basket',
      shipping_address: 'Shipping Address',
      firstname: 'Firstname',
      lastname: 'Lastname',
      address: 'Address',
      city: 'City',
      zip: 'Zip',
      phone: 'Phone',
      make_order: 'Make Order',
      invoice_details: 'Invoice Details',
      invoice_type: 'Invoice Type',
      invoice_type_receipt: 'Receipt',
      invoice_type_invoice: 'Invoice',
      company_name: 'Company Name',
      vat: 'Vat Number',
      tax_office: 'Tax Office',
      profession: 'Profession',
      payment_method: 'Payment Method',
      payment_method_on_delivery: 'On Delivery',
      payment_method_deposit: 'Bank Deposit',
      payment_method_online: 'Online Card',
      fetching_categories: 'Fetching Categories',
      no_products_in_list: 'No products added to this list',
      description: 'Description',
      change_header_to_fixed_tooltip: 'Make menu sticky to the top',
      change_header_to_normal_tooltip: 'Make menu back to normal',
      order_sent_message: "Your order has been placed successfuly.",
      input_coupon: "Enter coupon",
      discount: 'Discount',
      deliver_method: "Delivery Method",
      deliver_method_courier: "ACS",
      note: 'Note',
      date_high_to_low: 'Newest',
      no_products_found: 'No products found',
      availability: 'Available',
      notes: 'Notes',
      product_added_as_favourite: 'Product added as favourite',
      remove_from_favourite: 'Remove from favourite',
      choose: 'Choose',
      clear: 'Clear',
      building_floor: 'Building Floor',
      delivery_point_type: 'Delivery Point',
      products_view_type: 'Προβολή',
      grid: 'Grid',
      list: 'List',
      order_number: 'Code',
      delivery_point_type_office_hours: 'Please tell us the working hours',
    },
    el: {
      visit_time: 'Ανοιχτά',
      call_us: 'Καλέστε μας',
      monday_to_friday_and_hours: 'Δευ-Παρ 10:00-17:00',
      company_address: 'Σκιάθου 2, Ντεπώ',
      home_page: 'Αρχική',
      products_navigation: 'Προϊόντα',
      categories: 'Κατηγορίες',
      basket: 'Καλάθι Αγορών',
      contact: 'Επικοινωνία',
      blog: 'Blog',
      search: 'Αναζήτηση',
      close: 'Κλείσιμο',
      shop_by_category: 'Κατηγορίες',
      featured_products_title: 'Προτεινόμενα',
      favourite_products_title: 'Αγαπημένα',
      seen_products_title: 'Είδατε πρόσφατα',
      products: 'προϊόντα',
      get_in_touch: 'Επικοινωνήστε Μας',
      home: 'Αρχική',
      add_to_basket: 'Στο καλάθι',
      products_on_header: 'Σελίδα Προϊόντων',
      categories_on_header: 'Κατηγορίες του E-shop μας',
      contact_on_header: 'Σελίδα Επικοινωνίας',
      basket_on_header: 'Το Καλάθι σας',
      blog_on_header: 'Blog',
      signin_on_header: 'Σύνδεση Χρήστη',
      signup_on_header: 'Εγγραφή Χρήστη',
      checkout_on_header: 'Ολοκλήρωση Αγορών',
      your_basket: 'Το Καλάθι σας',
      subtotal: 'Υποσύνολο',
      continue_to_checkout: 'Ολοκλήρωση Παραγγελίας',
      view_cart: 'Σελίδα Καλαθιού',
      remove_product_from_basket: 'Αφαίρεση',
      empty_basket: 'Το καλάθι σας είναι άδειο',
      product_added: 'Το προϊόν προστέθηκε',
      to_your_basket: 'στο καλάθι σας',
      add_to_favourite: 'Προσθήκη στα Αγαπημένα',
      signin: 'Σύνδεση',
      signout: 'Αποσύνδεση',
      my_account: 'Ο Λογαριασμός μου',
      support: 'Υποστήριξη',
      help: 'Βοήθεια',
      contact_us: 'Επικοινωνία',
      feedback: 'Η Γνώμη σας',
      unsubscribe: 'Απεγγραφή',
      policies: 'Πολιτικές',
      privacy_policy: 'Πολιτική Απορήτου',
      terms_of_use: 'Όροι και Προϋποθέσεις',
      shipping: 'Αποστολή Προϊόντων',
      return_policy: 'Πολιτική Επιστροφών',
      stay_up_to_date: 'Μείνετε Ενημερωμένοι',
      newsletter_info: '*Εγγραφείτε στο ενημερωτικό μας δελτίο για να λαμβάνετε εκτπωτικές προσφορές, και ενημερώσεις για νέα προϊόντα.',
      subscribe: 'Εγγραφή',
      your_email: 'Το Email σας',
      name: 'Όνομα',
      lastname: 'Επώνυμο',
      email: 'Email',
      password: 'Κωδικός',
      remember_me: 'Να με θυμάσαι',
      dont_have_an_account: `Δεν έχετε λογαριασμό;`,
      signin_button: 'Σύνδεση',
      signup: 'Εγγραφή',
      alternatively: 'Εναλλακτικά',
      forgot_password: 'Ξεχάσατε τον κωδικό σας;',
      register_accept_terms: 'Με την εγγραφή, διαβάζω και αποδέχομαι του όρους χρήσης.',
      create_account_button: 'Δημιουργία Λογαριασμού',
      have_an_account: 'Έχετε λογαριασμό;',
      login_here: 'Συνδεθείτε εδώ',
      filters: 'Φίλτρα',
      sort_by: 'Ταξινόμηση',
      price: 'Τιμή',
      price_low_to_high: 'Αύξουσα τιμή',
      price_high_to_low: 'Φθίνουσα τιμή',
      image: 'Εικόνα',
      product_name: 'Όνομα Προϊόντος',
      unit_price: 'Τιμή Μονάδας',
      quantity: 'Ποσότητα',
      action: 'Ενέργειες',
      order_total: 'Σύνολο Παραγγελίας',
      taxes: 'Φ.Π.Α.',
      grand_total: 'Σύνολο',
      continue_shopping: 'Συνέχεια Αγορών',
      empty_basket: 'Δεν υπάρχουν προϊόντα στο καλάθι σας',
      shipping_address: 'Στοιχεία Αποστολής',
      firstname: 'Όνομα',
      lastname: 'Επώνυμο',
      address: 'Διεύθυνση',
      city: 'Πόλη',
      zip: 'Τ.Κ.',
      phone: 'Τηλέφωνο',
      make_order: 'Ολοκλήρωση Αγορών',
      invoice_details: 'Στοιχεία Τιμολογίου',
      invoice_type: 'Τύπος Παραστατικού',
      invoice_type_receipt: 'Απόδειξη',
      invoice_type_invoice: 'Τιμολόγιο',
      company_name: 'Επωνυμία Εταιρείας',
      vat: 'Α.Φ.Μ.',
      tax_office: 'ΔΟΥ',
      profession: 'Επάγγελμα',
      payment_method: 'Τρόπος Πληρωμής',
      payment_method_on_delivery: 'Αντικαταβολή',
      payment_method_deposit: 'Τραπεζική Κατάθεση',
      payment_method_online: 'Πληρωμή Online',
      fetching_categories: 'Φόρτωση Κατηγοριών',
      no_products_in_list: 'Δεν υπάρχουν προϊόντα σε αυτή τη λίστα',
      description: 'Περιγραφή',
      change_header_to_fixed_tooltip: 'Κάντε το menu να παραμείνει στην κορυφή',
      change_header_to_normal_tooltip: 'Γυρίστε το menu πίσω στο κανονικό',
      order_sent_message: "Η παραγγελία σας ολοκληρώθηκε",
      input_coupon: "Εισάγετε εκτπωτικό κωδικό",
      discount: 'Έκτπωση',
      deliver_method: "Μέθοδος Αποστολής",
      deliver_method_courier: "ACS",
      note: 'Σημείωση',
      date_high_to_low: 'Νεότερα',
      no_products_found: 'Δεν βρέθηκαν προϊόντα',
      availability: 'Διαθεσιμότητα',
      notes: 'Σχόλια',
      product_added_as_favourite: 'Το προϊόν προστέθηκε στα αγαπημένα σας',
      remove_from_favourite: 'Αφαίρεση από τα αγαπημένα',
      choose: 'Επιλέξτε',
      clear: 'Καθαρισμός',
      building_floor: 'Όροφος',
      delivery_point_type: 'Σημείο Παραλαβής',
      products_view_type: 'Προβολή',
      grid: 'Πλέγμα',
      list: 'Λίστα',
      order_number: 'Αριθμός Παραγγελίας',
      delivery_point_type_office_hours: 'Παρακαλώ σημειώστε στα σχόλια τις ώρες λειτουργίες τους καταστήματος.',
    },
  };

  setLanguage(languageCode) {
    this.language = languageCode;
    this.languageChangeFns.forEach((fn) => {
      fn();
    });
  }

  onLanguageChange(fn) {
    this.languageChangeFns.push(fn);

    return () => {
      this.languageChangeFns.splice(this.languageChangeFns.indexOf(fn), 1);
    };
  }

  t(key) {
    return this.translations[this.language][key];
  }
}

const i18n = new I18n();
window.i18n = i18n;

export default i18n;