import './HomePage.css';
import React from 'react';
import {
  Link,
} from "react-router-dom";
import i18n from '../i18n';
import Slider from '../slider/Slider';
import SkeletonCategoryItem from '../category-skeleton-item/SkeletonCategoryItem';
import CategoryItem from '../category-item/CategoryItem';
import SkeletonProductItem from '../product-skeleton-item/SkeletonProductItem';
import ProductItem from '../product-item/ProductItem';
import request from '../request';
import config from '../config';
import basket from '../basket';

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      products: [],
      sliderItems: [],
    };
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    request.get(`api/slider-items`).then((response) => {
      this.setState({
        ...this.state,
        sliderItems: response.data,
      });
    });

    request.get(`api/categories`).then((response) => {
      let data = response.data;
      data = data.sort((a, b) => {
        if (parseInt(a.productsCount) > parseInt(b.productsCount)) {
          return -1;
        } else {
          return 1;
        }
      });
      this.setState({
        ...this.state,
        categories: data.slice(0, 4).map((category) => {
          return {
            id: category.id,
            name: category.name,
            image: category.image,
            productCount: category.productsCount,
          };
        }),
      });
    });

    request.get(`api/products/featured`).then((response) => {
      this.setState({
        ...this.state,
        products: response.data.data.products.map((product) => {
          return {
            id: product.id,
            name: product.title,
            description: product.description,
            image: product.thumbnail,
            rating: product.rating,
            price: product.price,
            availability: product.availability,
            variants: product.variants,
          };
        }),
      });
    });
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  onAddToBasket(product) {
    basket.addProduct(product, 1);
  }

  render() {
    return (
      <div className="home-page">
        {
          this.state.sliderItems.length
            ? <Slider>
                {
                  this.state.sliderItems.map((sliderItem, i) => {
                    return (
                      <div
                        key={i}
                        className="item d-flex flex-direction-row justify-content-center align-items-center"
                      >
                        <div className="text m-5">
                          <div className="smallHeader fs-sm color-theme-text">{sliderItem.small_header}</div>
                          <div className="bigHeader fs-xxl color-theme-text">{sliderItem.big_header}</div>
                          <div className="mt-5">
                            <a href={`${sliderItem.action_url}`} className="btn btn-secondary btn-outline fs-lg">{sliderItem.action_text}</a>
                          </div>
                        </div>
                        <div
                          className="image"
                          style={{backgroundImage: `url('${config.imagesBaseURL}slider/${sliderItem.image}?v=2')`}}
                        ></div>
                      </div>
                    );
                  })
                }
              </Slider>
            : ''
        }
        <div className="section categories column">
          <h3 className="title">{i18n.t('shop_by_category')}</h3>
          <div className="list">
            {
              this.state.categories.map((category, i) => {
                return (
                  <CategoryItem
                    key={i}
                    data={category}
                  />
                );
              })
            }
            {
              this.state.categories.length === 0 && Array.from(Array(4)).map((n, i) => {
                return (
                  <SkeletonCategoryItem key={i} />
                );
              })
            }
          </div>
        </div>
        <div className="section featured-products column">
          <h3 className="title">{i18n.t('featured_products')}</h3>
          <div className="list">
            {
              this.state.products.map((product, i) => {
                return (
                  <ProductItem
                    key={i}
                    data={product}
                    onAddToBasket={() => this.onAddToBasket(product)}
                  />
                );
              })
            }
            {
              this.state.products.length === 0 && Array.from(Array(12)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i}></SkeletonProductItem>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;