import './AccountPage.css';
import React, { useEffect } from 'react';
import i18n from '../i18n';
import Modal from '../modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faLock, faHome, faBox, faLink, faEye } from '@fortawesome/free-solid-svg-icons';
import { faUser, faHeart, faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import { } from '@fortawesome/free-brands-svg-icons';
import history from '../history';
import request from '../request';
import Select from 'react-select';
import storage from '../storage';

class AccountPage extends React.Component {
  
  constructor(props) {
    super(props);   

    this.state = {      
      // variables
      checkingSignedInStatus: true,
      activeTab: 'tab1',      
      personalDataForm: {
        firstname: '',
        lastname: '',
        email: '',
        cell: '',
        phone: '',
        gender: '0',
      }, 
      // update message
      messageStatusUpdate: false,
      messageTypeUpdate: '', // danger od success
      messageUpdate: '', // server response message  
      passwordForm: {
        oldPassword: '',
        newPassword:'',
        confirmPassword: '',
      },
      addressData: [],
      addressDataForm: {
        address: '',  
        number: '1',
        floor: '1 όροφος',      
        region: 'martiou',
        city: 'thessaloniki',
        country: 'ellada',
        zip: '12345',
        notes: 'Σημειώσεις',
        type: '1'
      },  
      activeAddress:'active',
      modalStatus: false,
      message:'',
      messageStatus: false,
      showDeleteModal: false,
      deliveryTypeSelectedOptions: [],
      selectedOption: '1', 
      items: [],
      isLoaded: false,
      itemArray:[], // add new address block
      selectedOption: '1',
      ordersData: [],
    };  
   
    // bind 'this' to functions
    this.changeActiveTab = this.changeActiveTab.bind(this);   
    this.onGenderChange = this.onGenderChange.bind(this);
    this.submitPersonalData = this.submitPersonalData.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.addAddress = this.addAddress.bind(this);  
    this.getAddresses = this.getAddresses.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this); 
    this.updatePersonalDetail = this.updatePersonalDetail.bind(this);
    this.timer = this.timer.bind(this);
  }
  // end construct
  componentDidUpdate(prevProps, prevState) {
    if(this.state.activeTab == 'tab4' && this.state.ordersData.length == 0){
      request.get(`api/get-orders`).then((response) => {
        this.setState({
          ...this.state,
          ordersData: Object.values(response.data.orders),
        });
      });
    }
  }
  componentDidMount() {
    this.updatePersonalDetail();
    
    request.get(`api/status`).then((response) => {
      if (response.data && response.data.login) {
        this.setState({
          ...this.state,
          checkingSignedInStatus: false,
        });

        request.get('api/get-addresses').then((response) => {
          this.setState({
            ...this.state,
            isloaded: true,
            addressData: response.data.data,
          });
        });

        request.get('api/order-address-type').then((response) => {
          this.setState({
            ...this.state,
            isloaded: true,
            deliveryTypeSelectedOptions: response.data.map((option) => {
              return {
                label: option.name,
                value: option.id,
              };
            }),
          });
        });
      } else {
        history.push('/signin');
      }
    });
  }

  timer(str) {
    setTimeout(() => {
      this.setState({
        ...this.state,
        [str]: false
      })
    }, 3000);
  }

  getAddresses() {
    request.get('api/get-addresses').then((response) => {
      if (response.data.status) {
        this.setState({
          ...this.state,
          addressData: response.data,
        });
      }
    });
  }

  updatePersonalDetail() {
    request.get('api/get-user-personal-details').then((response) => {
     this.setState({
       ...this.state,
       personalDataForm:{
          ...this.state.personalDataForm,         
          firstname: response.data.firstname,        
          lastname: response.data.lastname,
          email: response.data.email,
          cell: response.data.cell,
          phone: response.data.phone,
          gender: response.data.gender,
       }       
     });
    });
  } 

  // delete address
  deleteAddress(id) {
    request.post('api/delete-address', {
      "address_id" : id,
    }).then( (response) => {   
      console.log(response);
      this.getAddresses();
    });

    this.setState({
      ...this.state,
      showDeleteModal: false
    });
  }

  createProject() {
      const item = this.state.itemArray;
      const title = '';
      const text = '';
      item.push({ title, text })
      this.setState({itemArray: item})
  }

  // show modal add address
  addAddress() {
    this.createProject();

    if (this.state.addressDataForm.address !== '' &&
        this.state.addressDataForm.number !== '' &&   
        this.state.addressDataForm.floor !== '' &&  
        this.state.addressDataForm.region !== '' &&
        this.state.addressDataForm.city !== '' &&
        this.state.addressDataForm.country !== '' &&
        this.state.addressDataForm.zip !== ''
    ) {
      request.post(`api/add-address`,{ 
        "address": this.state.addressDataForm.address,   
        "number": this.state.addressDataForm.number,   
        "floor": this.state.addressDataForm.floor,   
        "region": this.state.addressDataForm.region,
        "city": this.state.addressDataForm.city,
        "country": this.state.addressDataForm.country,
        "zip": this.state.addressDataForm.zip,
        "notes": this.state.addressDataForm.notes,
        "type": this.state.selectedOption
      }).then( (response) => {
        if( response.data.status === true ) {
          alert('Η νέα διεύθυνση προστέθηκε!');  
          this.getAddresses(); 
              
        } else {
          alert('Η διεύθυνση δεν προστέθηκε.');      
        }        

        this.setState({
          ...this.state,
          modalStatus: false
        });
      }); 
    } else {
      alert('Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία με *');        
    }
  }

  changeActiveTab(tab) {
    this.setState({
      ...this.state,
      activeTab: tab,
    });
  }

  // tab 1 perfonal data
  onTextInputChange(e, inputName) {
    this.setState({
      ...this.state,
      personalDataForm: {
        ...this.state.personalDataForm,
        [inputName]: e.target.value
      },      
    });
  }

  // modal add adress
  onInputChange(e, inputname) {
    this.setState({
      ...this.state,
      addressDataForm: {
        ...this.state.addressDataForm,
        [inputname]: e.target.value
      }
    })
  }

  showUpadateMessage(status,type,message) {
    this.setState({
      ...this.state,
      messageStatusUpdate: true,
      messageTypeUpdate: type,
      messageUpdate: message    
    })
  }

  submitPersonalData() {
     // data to send for update user's detail
     let id = this.state.id;
     let firstname = this.state.personalDataForm.firstname;
     let lastname = this.state.personalDataForm.lastname;
     let email = this.state.personalDataForm.email;
     let cell = this.state.personalDataForm.cell;
     let phone = this.state.personalDataForm.phone;
     let gender = this.state.personalDataForm.gender;     

    request.post( `api/update-user-personal-detail`, 
      {        
        "firstname" : firstname,
        "lastname" : lastname, 
        "email" : email,
        "cell" : cell,
        "phone" : phone,
        "gender" : gender  
      } ).then( (response) => {     

      if (response.data.status === false ){
        console.log('rows 0');
      }

      if (response.data.status === true ){
        this.updatePersonalDetail();
      }  

      this.showUpadateMessage(response.data.status, response.data.type, response.data.message);
      this.timer('messageStatusUpdate');
    });  
  }

  // tab 2 change password
  onPasswordChange(e, inputName) {
    const v =  e.target.value;
    
    this.setState({
      ...this.state,
      passwordForm: {
        ...this.state.passwordForm,
        [inputName]: v,        
      },
    }) 
  }  

  // Start change old password
  submitPassword() {    
    let oldPassword = this.state.passwordForm.oldPassword;
    let newPassword = this.state.passwordForm.newPassword;
    let confirmPassword = this.state.passwordForm.confirmPassword; 
    var message = ''; 
    var type = ''; 

    // if valid password
    if (this.state.passwordForm.oldPassword !== '' &&
          this.state.passwordForm.newPassword !== '' && 
          this.state.passwordForm.confirmPassword !== ''  && 
          this.state.passwordForm.newPassword === this.state.passwordForm.confirmPassword
        ) 
    {
      request.post('api/update-password', { "oldPassword" : oldPassword, "newPassword" : newPassword, "confirmPassword" : confirmPassword  }).then( (response) => {
        this.showMessage(response.data.status, response.data.message);
      });
    } 
    else if ( (oldPassword && newPassword && confirmPassword) === '' )
    {
      console.log('show errors');
      type = "alert-danger";
      message = 'Παρακαλώ συμπληρώστε όλα τα πεδία';
    } 
    else if ( newPassword !== confirmPassword )
    {
      message = 'Ο νέος κωδικός δεν είναι ίδιος με τον κωδικό επιβεβαίωσης';
      type = "alert-danger";
    }
  
    this.setState({
      ...this.state,
      messageStatus: true,
      messageType: type,
      message: message,      
    });  
    this.timer('messageStatus');
  }
  // End change old password

  showMessage(status, message) {
    let type = '';

    if ( status === false) {
      type = 'alert-danger';
    } else {
      type = 'alert-success';
    }

    this.setState({
      ...this.state,
      messageType: type,
      message: message
    });
  }    

  onGenderChange(e) {
    this.setState({
      ...this.state,
      personalDataForm: {
        ...this.state.personalDataForm,
        gender: e.target.value
      }
    })
  }

  changeActiveTab(tab) {
    this.setState({
      ...this.state,
      activeTab: tab,
    });
  }

  render() {
    return (
      <div className={`myaccount-page ${this.state.checkingSignedInStatus ? 'ninja' : ''}`}>
        <div className="tabs">
          <div
            className={`${this.state.activeTab == 'tab1' ? 'active' : ''}`}
            onClick={() => this.changeActiveTab('tab1')}
          >
            <div>
              <FontAwesomeIcon icon={faUser} />
              <span>Personal data</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div
            className={`${this.state.activeTab == 'tab2' ? 'active' : ''}`}
            onClick={() => this.changeActiveTab('tab2')}
          >
            <div>
              <FontAwesomeIcon className="icon-left" icon={faLock} />
              <span>Change Password</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div
            className={`${this.state.activeTab == 'tab3' ? 'active' : ''} ${this.state.checkingSignedInStatus ? 'hidden' : ''}`} 
            onClick={() => this.changeActiveTab('tab3')}
          >
            <div>
              <FontAwesomeIcon className="icon-left" icon={faHome} />
              <span>Address</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div 
            className={`${this.state.activeTab == 'tab4' ? 'active' : ''}`}
            onClick={() => this.changeActiveTab('tab4')}
          >
            <div>
              <FontAwesomeIcon className="icon-left" icon={faBox} />
              <span>Orders</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div
            onClick={() => {
              history.push('/products');
            }}
          >
            <div>
              <FontAwesomeIcon className="icon-left" icon={faHeart} />
              <span>Whishlist</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faLink} />
            </div>
          </div>
        </div>

        <div className="tabs-content">
          {/* tab 1 personal data */}
          <div className={`tab-personal-detail ${this.state.activeTab !== 'tab1' ? 'hidden' : ''}`}>
            <h4>Personal Detail</h4>
            <div className="container">
              <div className="w-50 col-left">
                <label className="form-label">First Name<span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.personalDataForm.firstname}
                  onChange={(e) => this.onTextInputChange(e, 'firstname')}                    
                />
              </div>

              <div className="w-50 col-right">
                <label className="form-label col-right">Last Name<span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.personalDataForm.lastname}
                  onChange={(e) => this.onTextInputChange(e, 'lastname') }
                />
              </div>

              <div className="w-100">
                <label className="form-label">Email address<span className="text-danger">*</span></label>
                <input
                  type="email"
                  className="form-control"
                  value={this.state.personalDataForm.email}
                  onChange={(e) => this.onTextInputChange(e, 'email')}
                />
              </div>

              <div className="w-50 col-left">
                <label className="form-label">Cell Number<span className="text-danger">*</span></label>
                <input
                  type="tel"
                  className="form-control"                 
                  value={this.state.personalDataForm.cell}
                  onChange={(e) => this.onTextInputChange(e, 'cell')}
                />
              </div>

              <div className="w-50 col-right">
                <label className="form-label">Phone Number</label>
                <input
                  type="tel"
                  className="form-control"
                  value={this.state.personalDataForm.phone}
                  onChange={(e) => this.onTextInputChange(e, 'phone')}
                />
              </div>               
            </div> {/* End container */}

            <div className="gender mb-20">
              <label className="form-label form-label-block">
                <span>Gender</span>
                <span className="text-danger">*</span>
              </label>
            
              <div className="form-check-inline">   
                <input type="radio"
                  className="form-check-input"
                  value="1"
                  checked={this.state.personalDataForm.gender === '1'}
                  onChange={(e) => { this.onGenderChange(e) }}
                />
                <span>Male</span>
              </div>

              <div className="form-check-inline"> 
                <input type="radio"
                  className="form-check-input"
                  value="2"
                  checked={this.state.personalDataForm.gender === '2'}
                  onChange={(e) => { this.onGenderChange(e) }}
                />
                <span>Female</span> 
              </div>

              {/* <div className="form-check-inline"> 
                <input type="radio"
                  className="form-check-input"
                  value="other"
                  checked={this.state.personalDataForm.gender === "other"}
                  onChange={(e) => { this.onGenderChange(e) }}
                />
                <span>Other</span>
              </div> */}
            </div>    

            <div className="mb-20">
                <div className={`alert ${this.state.messageTypeUpdate} ${this.state.messageStatusUpdate === false ? 'hidden' : '' }`}>
                  {this.state.messageUpdate}
                </div>  

              <input 
                type="submit"
                className="btn btn-outline"
                value="Save changes"
                onClick={this.submitPersonalData}
              />
            </div>

          </div>
          {/* End tab 1 personal data*/}




          {/* tab 2  change password*/}
          <div className={`${this.state.activeTab !== 'tab2' ? 'hidden' : ''}`}>
            <h4>Change Password</h4>
            <div className="container">
              <div className="w-100">
                <label className="form-label">
                  <span>Old-Password</span>
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  // autoComplete="new-password"
                  value={this.state.passwordForm.oldPassword}
                  onChange={(e) => this.onPasswordChange(e, 'oldPassword')}
                />
              </div>

              <div className="w-50 col-left">
                <label className="form-label">New Password<span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"  
                  // autoComplete="new-password" 
                  value={this.state.passwordForm.newPassword}
                  onChange={(e) => this.onPasswordChange(e, 'newPassword')}
                />
              </div>

              <div className="w-50 col-right">
                <label className="form-label">Confirm Password</label>
                <input
                  type="text"
                  className="form-control"
                  // autoComplete="new-password"
                  value={this.state.passwordForm.confirmPassword}
                  onChange={(e) => this.onPasswordChange(e, 'confirmPassword')}
                />
              </div>                

              <div>                
                <div className={`alert ${this.state.messageType} ${this.state.messageStatus === false ? 'hidden' : '' }`}>
                  {this.state.message}
                </div>               
                <input 
                  type="submit"
                  className="btn btn-outline"
                  value="Save changes"
                  onClick={this.submitPassword}
                />
              </div>

            </div>
          </div>

          {/* tab 3 */}
          <div className={`billing-address ${this.state.activeTab !== 'tab3' ? 'hidden' : ''}` }>
            <div className="mb-20">
              <h4 className="inline">Διευθύνσεις</h4>

              <span 
                className="add-address" 
                onClick={() => this.setState({...this.setState, modalStatus :true})}
              >  
                <FontAwesomeIcon icon={faTimesCircle} />
                <span>Προσθήκη νέας διεύθυνσης</span>
              </span>

              <Modal
                modalTitle="Προσθήκη νέας διεύθυνσης"
                showModal={this.state.modalStatus}  
                onClose={() => this.setState({ ...this.state, modalStatus: false })}               
              > 

                <p>Παρακαλώ συμπλήρώστε τα πεδία</p>

                <div className="container">

                 {/* <div className="w-100">
                    <label className="form-label">
                      <span>Διεύθυνση</span>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressDataForm.address}
                      onChange={ (e) => { this.onInputChange(e, 'address') }}
                    />
                  </div>   */}

                  <div className="w-50 col-left">
                    <label className="form-label col-left">
                      <span>Διεύθυνση</span>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressDataForm.address}
                      onChange={(e) => { this.onInputChange(e, 'address')  }}                    
                    />
                  </div>

                  <div className="w-50 col-right">
                    <label className="form-label col-right">
                      <span>Αριθμός</span>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressDataForm.number}
                      onChange={ (e) => { this.onInputChange(e, 'number') }}
                    />
                  </div>                                  

                  <div className="w-50 col-left">
                    <label className="form-label">
                      <span>Μέρος διεύθυνσης</span>
                      <span className="text-danger">*</span>
                    </label>
                   
                    {/* {JSON.stringify(this.state.selectedOption)} */}

                    <Select
                      options={this.state.deliveryTypeSelectedOptions}
                      value={this.state.selectedOption}
                      onChange={(selectedOption) => {
                        this.setState({                     
                          ...this.state,
                          selectedOption: selectedOption.value,
                        });
                      }}
                    />
                    

                  </div>

                  <div className="w-50 col-right">
                    <label className="form-label col-right">
                      <span>Όροφος</span>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressDataForm.floor}
                      onChange={ (e) => { this.onInputChange(e, 'floor') }}
                    />
                  </div>      

                  <div className="w-50 col-left">
                    <label className="form-label">
                      <span>Περιοχή</span>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressDataForm.region}
                      onChange={(e) => { this.onInputChange(e, 'region')  }}                    
                    />
                  </div>

                  <div className="w-50 col-right">
                    <label className="form-label col-right">
                      <span>Πόλη</span>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressDataForm.city}
                      onChange={ (e) => { this.onInputChange(e, 'city') }}
                    />
                  </div>                    

                  <div className="w-50 col-left">
                    <label className="form-label">
                      <span>Τ.Κ.</span>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressDataForm.zip}
                      onChange={ (e) => { this.onInputChange(e, 'zip') }}
                    />
                  </div>

                  <div className="w-50 col-right">
                    <label className="form-label">
                      <span>Χώρα</span>
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressDataForm.country}
                      onChange={ (e) => { this.onInputChange(e, 'country') }}
                    />
                  </div>   

                  <div className="w-100">
                    <label className="form-label">
                      <span>Σημειώσεις</span>                      
                    </label>
                    <textarea
                      className="form-control"
                      value={this.state.addressDataForm.notes}
                      onChange={ (e) => { this.onInputChange(e, 'notes') }}
                    >
                      {this.state.addressDataForm.notes}
                    </textarea>
                  </div>

                </div> {/* End container */}               

                <div className="mb-20">
                  <input 
                    type="submit"
                    className="btn btn-outline"
                    value="Προσθήκη"
                    onClick={this.addAddress}
                  />
                </div>

              </Modal>
            </div>
            {/* show address cards */}
            <div className="container billing">
              {
                this.state.addressData.map((address) =>{
                  return (
                    <div className={`col-50`} >
                      <div className="card-header">
                        <span>{address.address} {address.number}</span>

                        <span className="tooltip">
                          <span
                            className="delete-icon tooltip"
                            onClick={ () => this.setState({...this.state, showDeleteModal: true}) }
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                            <span className="tooltiptext">Διαγραφή</span>
                          </span>                 
                        </span>
                        
                        {/* Start delete modal */}
                        <Modal
                          modalTitle={`Θέλετε να διαγράψετε την διεύθυνση ${address.address}; `}
                          showModal={this.state.showDeleteModal}  
                          onClose={() => this.setState({ ...this.state, showDeleteModal: false })}               
                        >                     
                          <p>Επιβεβαίωση διαγραφής</p>                                

                          <div className="mb-20">
                            <input 
                              type="submit"
                              className="btn btn-outline"
                              value="Διαγραφή"
                              onClick={() => { this.deleteAddress(address.id)}}
                            />
                          </div>
                        </Modal>
                        {/* End delete modal */}
                      </div>
                      <div className="card-body">
                        <div className="d-flex flex-direction-column">
                          <span>Διεύθυνση: {address.address} {address.number}</span>
                          <span>Όροφος: {address.floor}</span>
                          <span>Περιοχή: {address.region}</span>                          
                          <span>Τ.Κ: {address.zip}</span>                          
                          <span>Πόλη: {address.city}</span> 
                          <span>Χώρα: {address.country}</span>                                               
                          <span>Σημειώσεις: {address.notes}</span>
                        </div>
                      </div>
                    </div>
                  );
                })
              }  
              </div>

              {/*Start add new address box on click*/}
              <div>
                  {this.state.itemArray.map((item, index) => {
                    return (
                      <div className="box" key={index}>
                          <div>
                          <h2>{item.title}</h2>
                          <p>{item.text}</p>
                        </div>
                      </div>
                    )
                  })}
                </div><div>
                  {this.state.itemArray.map((item, index) => {
                    return (
                      <div className="box" key={index}>
                          <div>
                          <h2>{item.title}</h2>
                          <p>{item.text}</p>
                        </div>
                      </div>
                    )
                  })}
              </div>
              {/*End add new address box on click*/}

          </div>

          {/* tab 4 */}
          <div className={`orders ${this.state.activeTab !== 'tab4' ? 'hidden' : ''}`}>
            <h4>My Orders</h4>
            <table className="table table-bordered align-middle">
              <thead>  
                <tr className="text-uppercase text-nowrap">
                  <th  className="text-dark text-center fw-500 image">Α/Α</th>
                  <th className="text-dark text-center fw-500">Ημερομηνία</th>
                  <th className="text-dark text-center fw-500">Τιμή</th>
                  {/* <th className="text-dark text-center fw-500">Προβολή</th> */}
                </tr>
              </thead>
              <tbody>
              {
                this.state.ordersData.map((order) => {
                  return (
                    <tr>
                      <td>{order.id}</td>
                      <td>{order.date}</td>
                      <td>{order.total_cost}</td>
                    </tr>
                  )
                })
              }               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountPage;